import React, { useState, useEffect } from 'react';
import { MenuItem } from './MenuItem';
import { FeedTicker } from '../Ticker/FeedTicker';

export const Navbar = (props) => {
  const [selectedItemId, setSelectedItemId] = useState(2);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSelect = (itemId) => {
    setSelectedItemId(itemId);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="bg-gray-200 py-2">
      <div className="container mx-auto flex items-center justify-between max-w-screen-xl px-4">
        <div className="left-section flex items-center flex-wrap">
          {props?.menuList.map((item) => (
            <React.Fragment key={item.id}>
              {item.leftImage && (
                <img
                  src={item.leftImage}
                  alt={`Left Image for ${item.label}`}
                  className={`mr-4 w-12 h-12 object-cover lg:w-15 lg:h-12 ${isMobile ? 'inline-block' : ''}`}
                />
              )}
              {isMobile && item.rightImage && (
                <img
                  src={item.rightImage}
                  alt={`Right Image for ${item.label}`}
                  className="w-12 h-12 object-cover inline-block"
                />
              )}
              <nav className={`hidden lg:flex ${isMobile ? 'ml-4' : ''}`}>
                <ul className="flex items-center space-x-2 lg:space-x-4">
                  <MenuItem item={item} onSelect={handleSelect} selectedItemId={selectedItemId} />
                </ul>
              </nav>
            </React.Fragment>
          ))}
        </div>
        <div className="right-section flex items-center">
          {props?.menuList[0]?.rightImage && !isMobile && (
            <img
              src={props.menuList[0].rightImage}
              alt={`Right Image for ${props.menuList[0].label}`}
              className="w-12 h-12 object-cover lg:w-15 lg:h-12 hidden lg:block"
            />
          )}
          <button
            className="lg:hidden flex items-center p-2"
            onClick={toggleMenu}
          >
            <img src="/images/menu/menu.png" alt="Menu Icon" className="w-8 h-8" />
          </button>
        </div>
      </div>

      {/* Collapsible Menu for mobile */}
      <nav className={`lg:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <ul className="flex flex-col items-center space-y-2 px-4 py-2">
          {props?.menuList.map((item) => (
            <MenuItem key={item.id} item={item} onSelect={handleSelect} selectedItemId={selectedItemId} />
          ))}
        </ul>
      </nav>
      <FeedTicker></FeedTicker>
    </div>
  );
};
