import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import { Navbar } from './Components/Navbar/Navbar';
import { Footer } from './Components/Footer/Footer';
import { About } from './Components/About';
import { Collaboration } from './Components/Collaboration';
import { Publications } from './Components/Publications';
import { People } from './Components/People';
import { Patent } from './Components/Patent';
import { Journal } from './Components/Journal';
import { Gallery } from './Components/Gallery';
import { Contact } from './Components/Contact';
import { Video } from './Components/Video';
import { News } from './Components/News';
import { Events } from './Components/Events';
import { Jobs } from './Components/Job';

// Mapping routes to components
const routeComponents = {
  '/about': About,
  '/collaboration': Collaboration,
  '/publications': Publications,
  '/people': People,
  '/patent': Patent,
  '/journals': Journal,
  '/gallery': Gallery,
  '/contact': Contact,
  '/video': Video,
  '/news': News,
  '/events': Events,
  '/jobs': Jobs,
};

export const App = () => {
  const [menuList, setMenuList] = useState(null);  // State to store the menu list

  // Fetch the menuList from public folder
  useEffect(() => {
    fetch('/data/menuList.json')
      .then((response) => response.json())
      .then((data) => setMenuList(data))
      .catch((error) => console.error('Error loading menu list:', error));
  }, []);

  // Check if menuList is loaded
  if (!menuList) {
    return <div>Loading menu...</div>;
  }

  return (
    <Router>
      <div>
        {/* Pass menuList to Navbar */}
        <Navbar menuList={menuList} />
         
      
        
        <Suspense fallback={<div>Loading components...</div>}>
          <Routes>
            {menuList.map((item) => {
              const Component = routeComponents[item.link] || (() => <div>Component Not Found</div>);
              return (
                <Route key={item.id} path={item.link || '/'} element={<Component />} />
              );
            })}
            <Route index element={<About />} /> {/* Default route */}
          </Routes>
        </Suspense>
        
        <Footer />
      </div>
    </Router>
  );
};
