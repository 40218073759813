import React from 'react';
import bannerImage from '../assets/images/banner/banner.jpg';
import ccyrnImage from '../assets/images/banner/ccyrn.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';

export const About = () => {
  return (
    <>
      <div
        className="relative w-100 mx-auto h-100 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: 'cover',
          minHeight: '70vh',
        }}
      >
        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center text-center text-white z-10">
          <div className="container">
            <h1 className="display-4 font-weight-bold text-dark">
              CCRYN - COLLABORATIVE CENTRE FOR MIND BODY INTERVENTION THROUGH YOGA - PGIMER
            </h1>
            <p className="lead mt-4 text-dark">
              The mission of CCRYN-Collaborative Centre for Mind Body Intervention through Yoga is to pioneer the implementation of the science of integrative health using evidence-based mind-body techniques.
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-4">
        <div className="row">
          <div className="col-md-4 d-flex justify-content-center mb-4 mb-md-0">
            <img src={ccyrnImage} alt="CCRYN Image" className="img-fluid rounded" />
          </div>
          <div className="col-md-8">
            <h3 className="h3 font-weight-bold mb-4 text-dark">
              About - CCRYN - Collaborative Centre for Mind Body Intervention through Yoga
            </h3>
            <p className="text-muted">
              CCRYN - Collaborative Centre for Mind Body Intervention through Yoga is established by Central Council for Research in Yoga & Naturopathy (CCRYN), Delhi and Post Graduate Institute of Medical Education & Research (PGIMER), Chandigarh as a Collaborative Research Centre at PGIMER, Chandigarh, India. The projects will be jointly conceived between CCRYN, Delhi and PGIMER, Chandigarh. CCRYN will fund the projects in its entirety, whereas PGI Chandigarh will provide for Institutional approval, expertise, patients and study subjects and office space for housing research personnel in its premises. Co-ordination and Monitoring of Research Activities will be done by a committee constituted by CCRYN and PGI Chandigarh. CCRYN and Collaborative Institute will make necessary arrangements for publishing the research data in authenticated Journals or wherever possible, in consultation with each other. All relevant patents/copyrights in the research programme and its findings would be in the name of the Principal Investigator, Co-investigators and the benefits of the above said patents/copyrights would be held for the benefit and use of Ministry of AYUSH, Govt. of India.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
