import React, { useState, useEffect } from 'react';
import Ticker from 'react-ticker';

// Loader Component
const Loader = () => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="w-8 h-8 border-4 border-t-4 border-white border-solid rounded-full animate-spin"></div>
  </div>
);

// Error Component
const Error = ({ message }) => (
  <div className="flex items-center justify-center w-full h-full">
    <p className="text-red-500 text-lg">{message}</p>
  </div>
);

export const FeedTicker = () => {
  const [tickerItems, setTickerItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTickerItems = async () => {
      try {
        // Replace with your API endpoint or local path
        const response = await fetch('/data/tickerItems.json');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setTickerItems(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTickerItems();
  }, []);

  return (
    <div className="ticker-container bg-sky-400 p-2 w-5/6 mx-auto mt-1" style={{ height: '40%' }}>
      {loading ? (
        <Loader />
      ) : error ? (
        <Error message={`Error loading ticker items: ${error}`} />
      ) : tickerItems.length > 0 ? (
        <Ticker>
          {({ index }) => (
            <a
              href={tickerItems[index % tickerItems.length].link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:underline px-5 inline-block"
            >
              {tickerItems[index % tickerItems.length].text}
            </a>
          )}
        </Ticker>
      ) : (
        <p className="text-white text-center">No ticker items available.</p>
      )}
    </div>
  );
};

export default FeedTicker;
